import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import apiClient from '../utils/apiClient';
import CryptoJS from "crypto-js";
import cryptoJs from 'crypto-js';


const CalendarWrapper = styled.div`
  .fc {
    max-width: 100%;
    margin: 0 auto;
    color: #333;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
  }
`;

const CalendarComponent = ({
  events = [],
  isEditable = true,
  onDateBlock = () => {},
}) => {
  const [localEvents, setLocalEvents] = useState(events);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isCompletedModalOpen, setIsCompletedModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    company_name: '',
    program_title: '',
    event_place: '',
    location: '',
    start_date: '',
    end_date: '',
    no_of_days: '',
    toc: '',
  });
  const [nonWorkingDays, setNonWorkingDays] = useState([]);
  const [completedDays, setCompletedDays] = useState([]);
  const [tempCompletedDays, setTempCompletedDays] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dateRange, setDateRange] = useState([]);


    const storedEncryptedUserID = sessionStorage.getItem('userID');
    const bytes = cryptoJs.AES.decrypt(storedEncryptedUserID, 'thirancoding360mgai');
    const userId = bytes.toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    setLocalEvents(events);
  }, [events]);

  const calculateDateRange = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate).toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const handleDateClick = (info) => {
    if (!isEditable) {
      alert('You do not have permission to block dates.');
      return;
    }
    setFormValues({
      company_name: '',
      program_title: '',
      event_place: '',
      location: '',
      start_date: info.dateStr,
      end_date: '',
      no_of_days: '',
      toc: null,
    });
    setIsEventModalOpen(true);
  };

  const handleEventClick = (info) => {
    const clickedEvent = localEvents.find((event) => event.id === info.event.id);
    if (clickedEvent) {
      setSelectedEvent(clickedEvent);

      const range = calculateDateRange(clickedEvent.start, clickedEvent.end);

      setNonWorkingDays([]);
      setTempCompletedDays(clickedEvent.completedDays || []);
      setDateRange(range);
      setIsCompletedModalOpen(true);
    }
  };

  const handleAddToCompletedDays = (date) => {
    if (!tempCompletedDays.includes(date)) {
      setTempCompletedDays((prev) => [...prev, date]);
    }
  };

  const handleRemoveFromCompletedDays = (date) => {
    setTempCompletedDays((prev) => prev.filter((d) => d !== date));
  };

  const handleSaveCompletedDays = () => {
    setCompletedDays(tempCompletedDays);

    const updatedEvent = {
      ...selectedEvent,
      completedDays: tempCompletedDays,
    };

    setLocalEvents((prevEvents) =>
      prevEvents.map((event) => (event.id === updatedEvent.id ? updatedEvent : event))
    );

    alert(`Completed Days saved: ${tempCompletedDays.join(', ')}`);
    setIsCompletedModalOpen(false);
  };

  const handleEventModalSave = async () => {
    if (
      !formValues.program_title ||
      !formValues.start_date ||
      !formValues.end_date
    ) {
      alert('Please fill all required fields.')
      return
    }

    const formData = new FormData()
    formData.append('user', userId)
    formData.append('program_title', formValues.program_title)
    formData.append('start_date', formValues.start_date)
    formData.append('end_date', formValues.end_date)
    formData.append('company_name', formValues.company_name)
    formData.append('event_place', formValues.event_place)
    formData.append('location', formValues.location)
    formData.append('no_of_days', formValues.no_of_days)
    formData.append('status', "Accepted")

    if (formValues.toc) {
      formData.append('toc', formValues.toc)
    }

    try {
      const response = await apiClient('trainer/program/', 'POST', formData)

      if (response.status === 200 || response.status === 201) {
        // Assuming the response contains the new event details
        const newEvent = {
          id: response.data.id, // Or any identifier from the API
          title: formValues.program_title,
          start: formValues.start_date,
          end: formValues.end_date,
          extendedProps: {
            company_name: formValues.company_name,
            event_place: formValues.event_place,
            location: formValues.location,
            no_of_days: formValues.no_of_days,
            toc: formValues.toc?.name || '',
          },
        }

        setLocalEvents((prevEvents) => [...prevEvents, newEvent])
        onDateBlock(newEvent) // If the parent component needs to know about this event
        setIsEventModalOpen(false) // Close the modal on success
      } else {
        alert('Failed to save the event. Please try again.')
      }
    } catch (error) {
      console.error('Error saving event:', error)
      alert('An error occurred while saving the event.')
    }
  }


  const handleEventModalClose = () => setIsEventModalOpen(false);
  const handleCompletedModalClose = () => setIsCompletedModalOpen(false);

  return (
    <CalendarWrapper>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth',
        }}
        events={localEvents}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        editable={isEditable}
        selectable={true} // Enable date range selection
        select={(info) => {
          // Handle the selected range
          setFormValues({
            company_name: '',
            program_title: '',
            event_place: '',
            location: '',
            start_date: info.startStr,
            end_date: info.endStr,
            no_of_days:
              Math.floor(
                (new Date(info.endStr) - new Date(info.startStr)) /
                  (1000 * 60 * 60 * 24)
              ) + 1,
            toc: null,
          })
          setIsEventModalOpen(true)
        }}
      />

      {/* Event Modal */}
      <Modal show={isEventModalOpen} onHide={handleEventModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <TextField
              fullWidth
              label="Company Name"
              name="company_name"
              value={formValues.company_name}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  company_name: e.target.value,
                }))
              }
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Program Title"
              name="program_title"
              value={formValues.program_title}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  program_title: e.target.value,
                }))
              }
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Event Place"
              name="eventPlace"
              value={formValues.event_place}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  event_place: e.target.value,
                }))
              }
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Location"
              name="location"
              value={formValues.location}
              onChange={(e) =>
                setFormValues((prev) => ({ ...prev, location: e.target.value }))
              }
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              name="startDate"
              value={formValues.start_date}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  start_date: e.target.value,
                }))
              }
              margin="normal"
              InputLabelProps={{ shrink: true }}
              required
            />
            <TextField
              fullWidth
              label="End Date"
              type="date"
              name="endDate"
              value={formValues.end_date}
              onChange={(e) =>
                setFormValues((prev) => ({ ...prev, end_date: e.target.value }))
              }
              margin="normal"
              InputLabelProps={{ shrink: true }}
              required
            />
            <TextField
              fullWidth
              label="No.of.Days"
              type="number"
              name="no_of_days"
              value={formValues.no_of_days}
              onChange={(e) =>
                setFormValues((prev) => ({ ...prev, no_of_days: e.target.value }))
              }
              margin="normal"
              required
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                margin: '16px 0',
                width: '100%',
              }}
            >
              <label
                htmlFor="upload-toc"
                style={{
                  fontSize: '1rem',
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginBottom: '4px',
                }}
              >
                TOC
              </label>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                  padding: '10px 12px',
                  backgroundColor: '#fff',
                  width: '100%',
                  cursor: 'pointer',
                  position: 'relative',
                }}
              >
                <input
                  id="upload-toc"
                  type="file"
                  accept=".pdf,.doc,.docx,.txt"
                  onChange={(e) => {
                    const file = e.target.files[0]
                    if (file) {
                      setFormValues((prev) => ({
                        ...prev,
                        toc: file,
                      }))
                    }
                  }}
                  style={{
                    position: 'absolute',
                    opacity: 0,
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                  }}
                  required
                />
                <span style={{ flex: 1 }}>
                  {formValues.toc
                    ? formValues.toc.name
                    : 'Choose a file...'}
                </span>
                <button
                  type="button"
                  style={{
                    backgroundColor: '#1976d2',
                    color: '#fff',
                    border: 'none',
                    padding: '6px 12px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    marginLeft: 'auto',
                  }}
                >
                  Browse
                </button>
              </div>
              {formValues.toc && (
                <p
                  style={{
                    marginTop: '8px',
                    fontSize: '0.875rem',
                    color: 'rgba(0, 0, 0, 0.6)',
                  }}
                >
                  Uploaded File: {formValues.toc.name}
                </p>
              )}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEventModalClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEventModalSave}>
            Save Event
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Completed Days Modal */}
      <Modal show={isCompletedModalOpen} onHide={handleCompletedModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Event Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Event Information</h5>
          {selectedEvent && (
            <div>
              <p>
                <strong>Company Name:</strong> {selectedEvent.company_name}
              </p>
              <p>
                <strong>Program Title:</strong> {selectedEvent.program_title}
              </p>
              <p>
                <strong>Event Place:</strong> {selectedEvent.event_place}
              </p>
              <p>
                <strong>Location:</strong> {selectedEvent.location}
              </p>
              <p>
                <strong>No.of.Days:</strong> {selectedEvent.no_of_days}
              </p>
              <p>
                <strong>Table of Content:</strong>{' '}
                {selectedEvent.toc}
              </p>
            </div>
          )}
          <Form>
            <h5>Completed Days</h5>
            <ListGroup>
              {tempCompletedDays.map((date, index) => (
                <ListGroup.Item key={index}>
                  {date}
                  <Button
                    size="sm"
                    variant="danger"
                    style={{ float: 'right' }}
                    onClick={() => handleRemoveFromCompletedDays(date)}
                  >
                    Remove
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>

            <h5 className="mt-4">Non-Working Days</h5>
            <ListGroup>
              {dateRange.map((date) => (
                <ListGroup.Item key={date}>
                  {date}
                  {tempCompletedDays.includes(date) ? (
                    <Button
                      size="sm"
                      variant="danger"
                      style={{ float: 'right' }}
                      onClick={() => handleRemoveFromCompletedDays(date)}
                    >
                      Remove
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      variant="success"
                      style={{ float: 'right' }}
                      onClick={() => handleAddToCompletedDays(date)}
                    >
                      Add
                    </Button>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSaveCompletedDays}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCompletedModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </CalendarWrapper>
  )
};

export default CalendarComponent;
