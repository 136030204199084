import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Alert,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import "./LoginPage.css";
import loginImage from "./images/signup1.avif"; // Replace with your login image path
import apiClient from "./utils/apiClient";
import CryptoJS from "crypto-js";

const LoginPage = ({ setIsLoggedIn, setUsername }) => {
  const [username, setLocalUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false); // New loading state
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true); // Start loading animation

    try {
      const response = await apiClient(
        "quiz/users/login/",
        "POST",
        JSON.stringify({
          email: username,
          password: password,
        }),
        {'Content-Type': 'application/json'}
      );

      setSuccess("Login successful!");
      setUsername(username);
      setIsLoggedIn(true);

      // Save username and user ID to localStorage
      localStorage.setItem("username", username);
      const encryptedUserID = CryptoJS.AES.encrypt(
        response.user_id.toString(),
        "thirancoding360mgai"
      ).toString();
      sessionStorage.setItem("userID", encryptedUserID);

      sessionStorage.setItem("token", response.access);

      // Navigate to dashboard
      if(response.role === "college") {
        navigate("/UserDashboard");
      } else if (response.role === "company") {
        navigate("/trainerDashboard");
      }
    } catch (err) {
      // console.error(err);
      setError("Invalid username or password. Please try again.");
    } finally {
      setLoading(false); // Stop loading animation
    }
  };

  const styles = {
    container: {
      marginTop: "2rem",
      padding: "3rem",
      background: "rgba(255, 255, 255, 0.8)",
      boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.3)",
      borderRadius: "20px",
    },
    heading: {
      textAlign: "center",
      marginBottom: "2rem",
      fontWeight: "bold",
      fontSize: "2.5rem",
    },
    span: {
      color: "#ffa003",
    },
    label: {
      color: "#ffa003",
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
    formGroup: {
      marginBottom: "1rem",
    },
    formControl: {
      height: "45px",
      borderRadius: "8px",
    },
    button: {
      width: "100%",
      padding: "10px",
      backgroundColor: "#ffa003",
      border: "none",
      color: "#ffffff",
      fontWeight: "bold",
      borderRadius: "8px",
      fontSize: "1rem",
    },
    alert: {
      textAlign: "center",
      marginBottom: "1rem",
    },
    leftImage: {
      width: "100%",
      borderRadius: "15px",
    },
  };

  return (
    <Container>
      <Row className="align-items-center">
        {/* Left Image Section */}
        <Col md={6} className="d-flex justify-content-center">
          <img src={loginImage} alt="Login" style={styles.leftImage} />
        </Col>

        {/* Right Login Form */}
        <Col md={6}>
          <div style={styles.container}>
            <h1 style={styles.heading}>
              <span style={styles.span}>Log</span> In
            </h1>
            {error && (
              <Alert style={styles.alert} variant="danger">
                {error}
              </Alert>
            )}
            {success && (
              <Alert style={styles.alert} variant="success">
                {success}
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group style={styles.formGroup}>
                <Form.Label style={styles.label}>Email</Form.Label>
                <Form.Control
                  style={styles.formControl}
                  type="text"
                  value={username}
                  onChange={(e) => setLocalUsername(e.target.value)}
                  placeholder="Enter your username"
                  required
                />
              </Form.Group>

              <Form.Group style={styles.formGroup}>
                <Form.Label style={styles.label}>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    style={styles.formControl}
                    type={passwordVisible ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    required
                  />
                  <InputGroup.Text
                    onClick={togglePasswordVisibility}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#ffa003",
                      color: "#fff",
                    }}
                  >
                    {passwordVisible ? "👁️" : "👁️‍🗨️"}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Button style={styles.button} type="submit" disabled={loading}>
                {loading ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Login"
                )}
              </Button>
            </Form>

            <div className="text-center mt-3">
              <Button
                variant="link"
                onClick={() => navigate("/Forget")}
                style={{
                  color: "#ffa003",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Forgot Password?
              </Button>
            </div>

            <div className="text-center mt-3">
              <span>Don't have an account? </span>
              <Button
                variant="link"
                onClick={() => navigate("/SignUp")}
                style={{
                  color: "#ffa003",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Sign Up
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
