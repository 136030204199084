import React from 'react';
import { Navbar, Nav, Button, Image, Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logo from './images/Codingboss-logo-1.png'; // Your logo image path
import './Navbar.css';

function NavbarComponent({ isLoggedIn, username, userRole, handleLogout }) {

    const navigate = useNavigate();

    const handleLogoClick = () => {
        if (isLoggedIn) {
            navigate('/UserDashboard');
        } else {
            navigate('/');
        }
    };
    const handleProfileClick = () => {
      if(userRole=='college'){
        navigate('/user-form')
        return
      }
      else if(userRole=='trainer'){
        navigate('/trainer-form')    
        return
      }
      navigate('/')
    }

    return (
      <div className="navigation-container">
             
  <Navbar expand="lg" className="custom-navbar">
  <Navbar.Brand className="brand-name">
  <img src={logo} alt="Logo" className="logo"  />
  <b onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
    Coding<span className="flash">boss</span>
  </b>
  </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              {/* Uncomment and add course items if needed */}
              {/* <NavDropdown title="Courses" id="basic-nav-dropdown">
            <NavDropdown.Item href="#">JAVA Programming (full course)</NavDropdown.Item>
            <NavDropdown.Item href="#">PYTHON Programming (full course)</NavDropdown.Item>
            <NavDropdown.Item href="#">Data Structures & Algo (JAVA/PYTHON)</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="#">Bootcamp</Nav.Link>
          <Nav.Link href="#">Techruit</Nav.Link> */}
            </Nav>

            {isLoggedIn && (
              <Nav>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <b>{username}</b>
                  <b style={{ fontSize: '12px', color: 'gray' }}>{userRole}</b>
                </div>
              </Nav>
            )}

            <Nav className="ml-auto acc-creation">
              {isLoggedIn ? (
                <Dropdown align="end">
                  <Dropdown.Toggle variant="link" className="profile-dropdown">
                    <Image
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4wTQySWL01Oo0Clt2jBav69Hxl5QjpNLbsQ&s"
                      roundedCircle
                      className="profile-icon"
                      onClick={handleProfileClick}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {userRole === 'member' && (
                      <Dropdown.Item as={Link} to="/Admindashboardg">
                        <b>Dashboard</b>
                      </Dropdown.Item>
                    )}
                    {userRole === 'member' && (
                      <Dropdown.Item as={Link} to="/Profile">
                        <b>My Profile</b>
                      </Dropdown.Item>
                    )}
                    {userRole === 'admin' && (
                      <Dropdown.Item
                        href="/"
                        onClick={handleLogout}
                      ></Dropdown.Item>
                    )}

                    <Dropdown.Item as={Link} to="/" onClick={handleLogout}>
                      <b>Logout</b>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <>
                  <Link to="/LoginPage">
                    <Button variant="warning" className="get-started-btn me-3">
                      <b>Login</b>
                    </Button>
                  </Link>
                  <Link to="/SignUp">
                    <Button variant="warning" className="get-started-btn me-3">
                      <b>Sign Up</b>
                    </Button>
                  </Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
}

export default NavbarComponent;
