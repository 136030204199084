import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaInstagram, FaLinkedin } from 'react-icons/fa'; // Importing social media icons
import './Footer.css';  // Assuming you have a separate CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          {/* Left Section: Social Media Links */}
          <Col md={4} className="footer-left">
            <div className="social-icons">
              <a href="https://www.instagram.com/thiran360ai" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} />
              </a>
              <a href="https://www.linkedin.com/company/thiran360ai/posts/?feedView=all" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={30} />
              </a>
            </div>
          </Col>

          {/* Center Section: Powered by Text */}
          <Col md={4} className="footer-center">
            <p>Powered by <strong>Thiran360AI</strong></p>
          </Col>

          {/* Right Section: Copyright Text */}
          <Col md={4} className="footer-right">
            <p>&copy; 2023 Thiran360AI. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
