import React, { useEffect, useState } from 'react';
import MCQQuiz from './MCQQuiz';
import apiClient from './utils/apiClient';
import { useLocation, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const McqTestPage = () => {
  const { state } = useLocation();
  const { subtype } = state || {};
  const { filterCategory } = state || {};
  const navigate = useNavigate();
  
  const [questions, setQuestion] = useState([
    { id: 1, question: 'Which of the following is used to declare a variable in Python?', options: ["var a","let a", "a = 10", "int a = 10"] },
    { id: 2, question: 'Which keyword is used to define a global variable in Python?', options: ["global", "static", "let", "const"] },
    // Add more questions here
  ]);

  const [questionStatus, setQuestionStatus] = useState({});

  // Define the updateQuestionStatus function
  const updateQuestionStatus = (questionId, status) => {
    setQuestionStatus(prevStatus => ({
      ...prevStatus,
      [questionId]: status,
    }));
  };

  const [userid, setUserid] = useState('');

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const storedEncryptedUserID = sessionStorage.getItem('userID');
        const bytes = CryptoJS.AES.decrypt(storedEncryptedUserID, 'thirancoding360mgai');
        setUserid(bytes.toString(CryptoJS.enc.Utf8));

        const data = await apiClient(`compiler/filter-by-subtype/?subtype=${subtype}`, 'GET');
        if (Array.isArray(data)) {
          // const orderedQuestions = data.sort((a, b) => a.question.id - b.question.id);
          console.log("received data", data)
          setQuestion(data);
        } else {
          // console.error('Unexpected data format:', data);
        }
      } catch (error) {
        // console.error('Error fetching MCQ data:', error);
      }
    };

    fetchQuestions();
  }, [subtype]);

  const submitTest = async (answers) =>{
    const payloadMcqEvaluate = {
      "answers": answers,
      "user_id":userid,
      "subtype":subtype,
      "type":filterCategory || 'Technical'
    };
    try {

      const responce = await apiClient(`compiler/evaluate/`, 'POST', JSON.stringify(payloadMcqEvaluate), {'Content-Type': 'application/json'});
    if(responce.user_id)
    {
      localStorage.setItem('submitMessage', 'Test Submitted Successfully!');
      navigate(-1);
    }
    } catch (error) {
      // console.error('Error fetching MCQ data:', error);
    }

  }

  return <MCQQuiz questions={questions} updateQuestionStatus={updateQuestionStatus} submitTest = {submitTest} />;
};

export default McqTestPage;
