const BASE_URL = 'https://api.codingboss.in/'

const apiClient = async (
  endpoint,
  method = 'GET',
  body = null,
  customHeaders = {}
) => {
  const url = `${BASE_URL}${endpoint}`
  const token = sessionStorage.getItem('token')
    ? `Bearer ${sessionStorage.getItem('token')}`
    : null

  // List of endpoints where Authorization header should not be included
  const noAuthEndpoints = [
    'quiz/users/login/',
    'trainer/organizations/',
    'quiz/send-otp/',
    'quiz/create-user/',
  ]

  // Normalize endpoint to avoid trailing slash or query parameter mismatches
  const normalizedEndpoint = endpoint.split('?')[0].replace(/\/+$/, '')

  const headers = {
    Accept: 'application/json',
    ...customHeaders,
  }

  // Add Authorization header unless the endpoint is in the noAuthEndpoints list
  if (token && noAuthEndpoints.includes(normalizedEndpoint)) {
    console.log('t or f' + !noAuthEndpoints.includes(normalizedEndpoint))
    headers.Authorization = token
  }

  const options = {
    method,
    headers,
  }

  if (body && method !== 'GET') {
    options.body = body
  }

  try {
    const response = await fetch(url, options)

    // Capture and log response details for debugging
    const responseData = await response.json()
    // console.log('API Response:', responseData)

    if (!response.ok) {
      // Include error details in the exception
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${
          responseData?.message || response.statusText
        }`
      )
    }

    return responseData // Return parsed JSON response
  } catch (error) {
    console.error('API call error:', error)
    throw error // Rethrow for the calling code to handle
  }
}

export default apiClient
