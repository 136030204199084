import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  Alert,
} from 'react-bootstrap'
import Navbar from './Navbar'
import { FaLock } from 'react-icons/fa' // Import lock icon
import apiClient from './utils/apiClient'

const Assignments = ({ isLoggedIn, userRole, handleLogout, username }) => {
  const navigate = useNavigate()
  const [programmingQuestions, setProgrammingQuestions] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [showAll, setShowAll] = useState(false) // State to toggle visibility

  const visibleQuestions = showAll
    ? programmingQuestions
    : programmingQuestions.slice(0, 2)
  const mcqCount = 10 // Static number of MCQs

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/LoginPage')
    }
  }, [])

 useEffect(() => {
   const fetchQuestions = async () => {
     try {
       const data = await apiClient('compiler/questions/', 'GET', null,)
       setProgrammingQuestions(data)
     } catch (error) {
       setError(
         error.message || 'Failed to fetch questions. Please try again later.'
       )
     } finally {
       setLoading(false)
     }
   }

   fetchQuestions()
 }, [])


  const handleProgrammingStartClick = (question) => {
    navigate('/QuestionPage', {
      state: { questionId: question.id, question },
    })
  }

  const handleMcqStartClick = () => {
    navigate('/mcq-test')
  }

  if (loading) {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    )
  }

  if (error) {
    return (
      <Container className="text-center">
        <Alert variant="danger">{error}</Alert>
      </Container>
    )
  }

  return (
    <>
      <Navbar
        isLoggedIn={isLoggedIn}
        username={username}
        userRole={userRole}
        handleLogout={handleLogout}
      />
      <Container className="mt-4">
        <h1 className="text-center mb-4">Assignments</h1>

        {/* Programming Questions Section */}
        <Row className="justify-content-center">
          <Col md={12} className="mb-4">
            <Card className="topic-card">
              <Card.Body>
                <Card.Title>Programming Questions</Card.Title>
                <Row>
                  {visibleQuestions.map((question, index) => (
                    <Col key={question.id} md={12} className="mb-4">
                      <Card className="question-card">
                        <Card.Body className="d-flex justify-content-between align-items-center">
                          <span
                            style={{
                              filter:
                                index >= 2 && !showAll ? 'blur(3.5px)' : 'none', // Blur text for locked questions
                            }}
                            title={
                              index >= 2 && !showAll
                                ? 'Unlock by completing previous levels'
                                : ''
                            } // Tooltip for locked questions
                          >
                            {question.title}: {question.description}
                          </span>
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() =>
                              handleProgrammingStartClick(question)
                            }
                            disabled={index >= 2 && !showAll} // Lock the button if it's for questions 3 and beyond
                            className="d-flex flex-column align-items-center justify-content-center"
                            style={{
                              minWidth: '80px',
                              height: '30px',
                              backgroundColor:
                                index >= 2 && !showAll ? '#cccccc' : '#017a8c', // Change background to gray if locked, otherwise keep blue
                              borderColor:
                                index >= 2 && !showAll ? '#999999' : '#017a8c', // Optional: change border color when locked
                            }}
                          >
                            {index >= 2 && !showAll ? (
                              <FaLock
                                style={{ fontSize: '1.2em', color: 'gray' }}
                              /> // Lock icon
                            ) : (
                              'Start' // Start text for unlocked
                            )}
                          </Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
                {/* Show More Button */}
                {/* {!showAll && programmingQuestions.length > 2 && (
                  <div className="text-center">
                    <Button
                      variant="link"
                      onClick={() => setShowAll(true)}
                      style={{ textDecoration: 'none', fontWeight: 'bold' }}
                    >
                      See More
                    </Button>
                  </div>
                )} */}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* MCQ Questions Section */}
        <Row className="justify-content-center">
          <Col md={12} className="mb-4">
            <Card className="topic-card">
              <Card.Body>
                <Card.Title>MCQ Questions</Card.Title>
                <Row>
                  <Col md={12}>
                    <Card className="question-card">
                      <Card.Body className="d-flex justify-content-between align-items-center">
                        <span>Total Questions: {mcqCount}</span>
                        <Button
                          variant="primary"
                          className="d-flex flex-column align-items-center justify-content-center"
                          size="sm"
                          onClick={handleMcqStartClick}
                          style={{
                            minWidth: '80px',
                            height: '30px',
                            backgroundColor: '#017a8c',
                            borderColor: '#017a8c',
                          }}
                        >
                          Start
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Assignments
