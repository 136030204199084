import React, { useEffect, useState } from 'react';
import { Card, Button, Container, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import apiClient from './utils/apiClient';
import CryptoJS from 'crypto-js';

const CategoryList = ({ isLoggedIn, userRole, handleLogout, username }) => {
  const [categories, setCategories] = useState([]);
  const [subtypes, setSubtypes] = useState({});
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingSubtypes, setLoadingSubtypes] = useState(null);
  const [error, setError] = useState(null);
  const [filterCategory, setFilterCategory] = useState('Technical');
  const navigate = useNavigate();

  const storedEncryptedUserID = sessionStorage.getItem('userID');
  const bytes = CryptoJS.AES.decrypt(storedEncryptedUserID, 'thirancoding360mgai');
  const userID = bytes.toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/LoginPage');
    }
  }, [isLoggedIn, navigate]);

  const [submitMsg, setSubmitMsg] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        if(localStorage.getItem('submitMessage')){
          setSubmitMsg(localStorage.getItem('submitMessage'));
          localStorage.removeItem('submitMessage');
        }
        const token = 'Bearer ' + sessionStorage.getItem('token');
        const data = await apiClient('compiler/get-category/', 'GET');
        setCategories(data.categories || []);
      } catch (error) {
        setError('Server maintenance is scheduled until 6 AM. Please try again after that.');
      } finally {
        setLoadingCategories(false);
      }
    };



    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSubtypes = async () => {
      if (filterCategory) {
        setLoadingSubtypes(filterCategory);
        try {
          const response = await apiClient(`compiler/get-subtype/?category=${filterCategory}`, 'GET');
          setSubtypes((prev) => ({
            ...prev,
            [filterCategory]: response.subtypes || [],
          }));
        } catch (error) {
          // console.error(`Error fetching subtypes for ${filterCategory}:`, error);
          setError(`Failed to fetch subtypes for ${filterCategory}. Please try again later.`);
        } finally {
          setLoadingSubtypes(null);
        }
      }
    };

    fetchSubtypes();
  }, [filterCategory]);

  const handleViewSubtypes = async (category) => {
    if (filterCategory === category) {
      setFilterCategory(null);
      return;
    }

    setLoadingSubtypes(category);
    try {
      const response = await apiClient(`compiler/get-subtype/?category=${category}`, 'GET');
      setSubtypes((prev) => ({
        ...prev,
        [category]: response.subtypes || [],
      }));
      setFilterCategory(category);
    } catch (error) {
      // console.error(`Error fetching subtypes for ${category}:`, error);
      setError(`Data Not Available to the subtypes for ${category}. Please try again later.`);
    } finally {
      setLoadingSubtypes(null);
    }
  };

  const handleNavigateToMcqTestPage = (subtype) => {
    navigate('/McqTestPage', { state: { subtype }}, {filterCategory: {filterCategory}});
  };

  if (loadingCategories)
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <div className="loading-animation" style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
          <div style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: '#007bff', animation: 'bounce 1s infinite' }}></div>
          <div style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: '#007bff', animation: 'bounce 1s infinite', animationDelay: '0.2s' }}></div>
          <div style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: '#007bff', animation: 'bounce 1s infinite', animationDelay: '0.4s' }}></div>
        </div>
        <p style={{ fontSize: '18px', color: '#555' }}>Loading Categories...</p>
        <style>{`
          @keyframes bounce {
            0%, 80%, 100% {
              transform: scale(0);
            }
            40% {
              transform: scale(1);
            }
          }
        `}</style>
      </div>
    );

  if (error)
    return (
      <div className="text-center text-danger">
        <p>{error}</p>
      </div>
    );

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} username={username} userRole={userRole} handleLogout={handleLogout} />
      <Container style={{ maxWidth: '1200px', margin: '0 auto', padding: '15px' }}>
        <h2 className="text-center my-4">Explore <span style={{ color: '#007bff' }}>Quiz Categories</span></h2>

        {/* Arch-shaped buttons */}
        <div className="level-buttons-container">
          <button
            className={`level-button technical ${filterCategory === 'Technical' ? 'selected' : ''}`}
            onClick={() => handleViewSubtypes('Technical')}
          >
            Technical
          </button>
          <button
            className={`level-button aptitude ${filterCategory === 'Aptitude' ? 'selected' : ''}`}
            onClick={() => handleViewSubtypes('Aptitude')}
          >
            Aptitude
          </button>
          <button
            className={`level-button softskill ${filterCategory === 'SoftSkill' ? 'selected' : ''}`}
            onClick={() => handleViewSubtypes('SoftSkill')}
          >
            SoftSkill
          </button>
        </div>
        <h5 style={{ color: '#2E7D32', margin: 0, fontWeight: '600', fontSize: '1.25rem' }}>
        {submitMsg}
   </h5>
        {/* Subtypes Display */}
        {filterCategory && subtypes[filterCategory] && (
          <Row>
            {subtypes[filterCategory].map((subtype, index) => (
              <Col key={index} md={12} className="mb-4">
                <Card className="question-card">
                  <Card.Body className="d-flex justify-content-between align-items-center">
                    <span>{subtype}</span>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => handleNavigateToMcqTestPage(subtype)}
                    >
                      Start
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )}

        {/* Show loading spinner for subtypes */}
        {loadingSubtypes && filterCategory === loadingSubtypes && (
          <div className="text-center">
            <Spinner animation="border" variant="secondary" /> Loading Subtypes...
          </div>
        )}
      </Container>
    </>
  );
};

export default CategoryList;
